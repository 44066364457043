import React, { useRef, useState } from "react"
import axios from "axios"
import "./index.css"

const defaultFormContact = {
  nombre: "",
  correo: "",
  celular: "",
  asunto: "Te enviaron un Curriculum - agenteinmobiliario.cl",
  mensaje: "",
  tipo: "Corredor",
  loading: false,
  request: false,
  requestMessage: "",
}

const ContactFormFile = () => {
  const [form, setForm] = useState(defaultFormContact)
  const ref = useRef(null)
  const onChangeInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const requestForm = async () => {
    setForm({ ...form, loading: true })
    var formdata = new FormData()
    formdata.append("name", form.nombre)
    formdata.append("email", form.correo)
    formdata.append("mobile", form.celular)
    formdata.append("subject", form.asunto)
    formdata.append("message", form.mensaje)
    formdata.append("type", form.tipo)
    formdata.append("file", ref.current.files[0])

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      "https://www.agenteinmobiliario.cl/mailApi/sendmail.php",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        handleFormReset()
        setForm({
          ...defaultFormContact,
          loading: false,
          requestMessage: <p style={{ color: "green" }}>Mensaje enviado!</p>,
        })
      })
      .catch(error =>
        setForm({
          ...form,
          loading: false,
          requestMessage: (
            <p style={{ color: "red" }}>Error intenta nuevamente!</p>
          ),
        })
      )
  }

  const onSubmit = e => {
    e.preventDefault()
    requestForm()
  }

  const handleFormReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    )

    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    )
  }

  return (
    <form
      className="contact-form"
      onSubmit={event => onSubmit(event)}
      enctype="multipart/form-data"
    >
      <p>
        <label for="name">Nombre</label>
        <input
          type="text"
          name="nombre"
          onChange={event => onChangeInput(event)}
          title="* Porfavor ingrese su nombre"
          required
        />
      </p>

      <p>
        <label for="email">Email</label>
        <input
          type="email"
          name="correo"
          title="* Porfavor ingrese su correo"
          style={{
            width: "40.4%",
            height: "24px",
            borderRadius: "0%",
            marginBottom: "0px",
          }}
          onChange={event => onChangeInput(event)}
          required
        />
      </p>

      <p>
        <label for="email">Celular</label>
        <input
          type="text"
          name="celular"
          title="* Porfavor ingrese su celular"
          onChange={event => onChangeInput(event)}
          required
        />
      </p>

      <p>
        <label for="comment">Mensaje</label>
        <textarea
          name="mensaje"
          required
          onChange={event => onChangeInput(event)}
          style={{ width: " 44.4%", height: "44.4%" }}
        ></textarea>
      </p>
      <p>
        <label for="email">Cargo</label>
        <select name="tipo" onChange={event => onChangeInput(event)}>
          <option value="Corredor" selected>
            Corredor
          </option>
          <option value="Captador">Captador</option>
          <option value="Bróker">Bróker</option>
        </select>
      </p>
      <p>
        <label for="comment">Ingrese su curriculum</label>
        <input type="file" ref={ref} name="cv" />
      </p>
      <input
        type="hidden"
        name="asunto"
        value="Contacto desde la web de AgenteInmobiliario"
      />
      <div style={{ display: "flex" }}>
        {" "}
        <input
          disabled={form.loading}
          type="submit"
          value={form.loading ? "Enviando" : "Enviar mensaje"}
          id="submit"
          className="real-btn"
          name="submit"
        />
        <div
          style={{
            padding: "5px 20px",
            fontSize: "15px",
            fontWeight: "bold",
          }}
        >
          {" "}
          {form.requestMessage !== "" && !form.loading && form.requestMessage}
        </div>
      </div>
      <div className="error-container"></div>
      <div id="message-sent">&nbsp;</div>
    </form>
  )
}

export default ContactFormFile
